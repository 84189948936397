@media (hover: none) {
  /* utility classes */

  .p-project-name-style {
    margin-block-end: 3rem;
  }

  .project-content {
    bottom: 0;
    height: max-content;
    opacity: 1;
    padding: 2em;
  }

  .project-detail > p {
    display: none;
  }

  .project-detail + a {
    margin-block-start: 2rem;
  }
}

@media screen and (max-width: 106.25em) {
  .project-content {
    padding: 3.5rem;
  }
}

/* medium screen */

@media screen and (max-width: 100em) {
  .view-only-01 {
    grid-column: 9 / 13;
  }

  .view-only-02 {
    grid-column: 1 / 5;
  }

  .my-projects-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .project-container.project-8 {
    display: flex;
  }
}

/* large desktop footer only */

@media screen and (max-width: 98.75em) {
  .footer-right-content {
    margin-block: 0;
  }
}

/* index page --- ipad-pro */

@media screen and (max-width: 87.5em) {
  .hero-text {
    max-width: 100%;
  }

  /* about us css */

  .about-me-container {
    flex-direction: column-reverse;
    gap: 3.5rem;
    margin-block: 0;
  }

  .about-me-img-1 {
    border-top-left-radius: 1.25em;
  }

  .about-me-img-3 {
    border-bottom-right-radius: 1.25em;
  }

  .about-me-images-grid {
    gap: 8px;
    min-height: 15.5rem;
    min-width: 18.5rem;
  }

  .about-me-grid-bg-pattern {
    display: none;
  }

  /* my work css */

  :is(.my-work-container, .my-tools-container) {
    background: none;
    grid-template-columns: repeat(6, 1fr);
  }

  .my-work-container-left,
  .my-tools-container-right {
    grid-column: 2 / 6;
  }

  .my-projects-grid {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  :is(.highlight-01, .highlight-02, .view-only-01, .view-only-02) {
    display: none;
  }

  /* things i do css */

  .things-i-do-list-main-container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 0.5rem;
    row-gap: 2.5rem;
  }

  .things-i-do-list > p {
    margin-bottom: 1rem;
  }

  .things-i-do-list-container {
    gap: 1rem;
    padding-right: 0;
  }

  /* contact me css */

  .contact-me-content {
    justify-content: center;
  }

  /* utility classes change */

  br.web {
    display: none;
  }

  br.mobile {
    display: block;
  }

  .section-margin {
    margin-block: 5rem;
  }
}

/* index page --- mobile and ipad*/

@media screen and (max-width: 63.75em) {
  /* things i do css */

  .things-i-do-list-main-container {
    grid-template-columns: repeat(2, 1fr);
  }
  :is(.my-tools-container) {
    grid-template-columns: 0;
  }
}

/* my project grid will turn "2" to "1" in mobile */

@media screen and (max-width: 56em) {
  :root {
    /* font Sizes */
    --type-p-large: 1rem;
    --type-caption: 1.25rem;
    /* --type-heading-h1: 2.5rem; */
    /* --type-heading-h2: 3rem; */
    --type-heading-h3: 4rem;
    --type-heading-h4: 3rem;
    /* --type-heading-h5: 1.15rem; */
    --type-heading-h6: 1.5rem;

    /* line heights */
    --p-large-line-height: 1.5rem;
    --caption-line-height: 2rem;
    /* --heading-h1-line-height: 3rem; */
    /* --heading-h2-line-height: 2.625rem; */
    --heading-h3-line-height: 5.25rem;
    --heading-h4-line-height: 3.75rem;
    /* --heading-h5-line-height: 1.75rem; */
    --heading-h6-line-height: 1.875rem;
  }

  /* utility classes changes */

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .p-project-name-style {
    margin-inline: auto;
  }

  /* my works section */

  .my-projects-grid {
    grid-template-columns: 1fr;
  }

  .tags {
    margin-inline: auto;
  }

  .project-content {
    padding: 2em;
  }

  .project-detail-container {
    margin-inline: auto;
  }

  .project-detail + a {
    margin-inline: auto;
  }

  /* .project-detail > p {
    display: none;
  } */

  .my-tools-list {
    justify-content: center;
    margin-inline: auto;
  }

  .things-i-do-list-main-container {
    grid-template-columns: repeat(1, 1fr);
    gap: 2.5rem;
  }

  br.small-mobile {
    display: block;
  }
  .my-work-container-left,
  .my-tools-container-right {
    grid-column: -1;
  }
}

/* device pixel ratio - for 125% zoom windows */
@media (max-width: 120em),(-webkit-device-pixel-ratio: 1.25)  {
  :root {
    --type-link: 0.875rem;
    --type-heading-h1: clamp(2.5rem, 10vw, 4.5rem);
    --type-heading-h2: clamp(2.625rem, 10vw, 3.5rem);
    --type-p: 0.875rem;
    --type-p-large: 1.125rem;
    --type-caption: 1.125rem;
  }
}

/*@media (hover: none) and (max-width: 23em) {*/
/*  .project-content {*/
/*    height: max-content;*/
/*  }*/
/*}*/
