:is(
    .footer-content,
    .footer-left-content,
    .footer-left-container,
    .footer-left-items,
    .footer-right-content,
    .footer-right-container,
    .footer-list,
    .footer-social-media-links
  ) {
  display: flex;
  flex-flow: column wrap;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

footer.footer-container {
  background: linear-gradient(
    to right,
    var(--primary--400) 50%,
    var(--gray-200) 50%
  );
}

:is(.footer-left-container, .footer-right-container) {
  padding-block: 8rem 15rem;
}

.footer-content {
  flex-flow: row;
  margin-inline: auto;
  max-width: 102.5rem;
}

.footer-left-content {
  align-items: start;
  gap: 2.5rem;
}

.footer-left-content img {
  max-width: 11.25rem;
  min-width: 11.25rem;
}

.footer-left-container {
  background-color: var(--primary--400);
  flex-flow: row wrap;
  min-width: 70%;
}

.footer-left-items {
  flex-flow: row wrap;
  gap: calc(5vh + 5px) calc(5vw + 5px);
}

.footer-list {
  gap: 0.5rem;
}

.footer-right-content {
  gap: 2.5rem;
  margin-block: auto 0;
  padding-left: 3.75rem;
}

.footer-right-container {
  background-color: var(--gray-200);
  flex-flow: row wrap;
  min-width: 30%;
}

.footer-social-media-links {
  flex-flow: row wrap;
  gap: 2rem;
}

/* mobile media query */

@media screen and (max-width: 47.75em) {
  .footer-left-container {
    padding-block: 3rem;
  }

  .footer-right-container {
    padding-block: 3rem 8rem;
  }

  .footer-left-content img {
    max-width: 10.25rem;
    min-width: 10.25rem;
  }

  :is(.footer-left-container, .footer-right-container, .footer-content) {
    flex-flow: column;
  }

  .footer-left-content {
    align-items: center;
    gap: 4rem;
  }

  .footer-left-items {
    justify-content: center;
    flex-flow: column;
    gap: 2.5rem;
  }

  .footer-social-media-links {
    justify-content: center;
  }

  footer > .main-container {
    max-width: 100%;
  }

  .footer-left-content,
  .footer-right-content {
    padding-inline: 2rem;
  }
}
