.main {
  position: relative;
  z-index: 3;
  background-color: var(--secondary--500);
}

.bg-grid-item:not(:last-child) {
  border-right: 1px solid var(--gray-800);
}

/* hero section */

.hero-section_bg-img {
  background-repeat: no-repeat;
  mix-blend-mode: lighten;
  min-height: 100vh;
  object-fit: cover;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.hero-section {
  align-items: center;
  display: flex;
  min-height: 100vh;
}

.hero-content {
  display: flex;
  flex-flow: column wrap;
  padding-bottom: 7.5rem;
}

.hero-content h1 {
  margin-block: 0.25em 0.5em;
}

.hero-text {
  background-color: var(--primary--300);
  border-top: 0.75rem solid var(--primary--400);
  text-align: center;
  margin-inline: auto;
  max-width: 72.5rem;
  padding-block: 2em;
  width: 100%;
}

/* about us section */

.about-me-container {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 9.625rem;
  position: relative;
  margin-block: 0 16rem;
}

.about-me-grid-bg-pattern {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.about-me-images-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr 2fr;
  max-height: 42.5rem;
  max-width: 50.75rem;
}

/*.about-me-images-grid.lazy {*/
/*  height: 42.5rem;*/
/*  width: 50.75rem;*/
/*}*/

.about-me-img-1 {
  border-top-left-radius: 2.5em;
  grid-area: 1 / 1;
}

.about-me-img-2 {
  grid-area: 2 / span;
}

.about-me-img-3 {
  border-bottom-right-radius: 2.5em;
  grid-area: 1 / 2 / -1 / -1;
}

.about-me-images-grid > img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.about-me-container-right {
  display: flex;
  flex-flow: column wrap;
  max-width: 33.5rem;
}

/* my work */

.my-work-wrapper {
  --_c-img-b-r: 1rem;
  max-width: 102.5rem;
  margin-inline: auto;
}

.my-work-container {
  align-items: center;
  background-color: var(--secondary--400);
  border-top-left-radius: 2.5rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  margin-inline: auto;
}

.my-work-container-left {
  grid-column: 2 / 6;
  padding-block: 6.25rem;
}

.highlight-01 {
  border-radius: var(--_c-img-b-r);
  grid-row: -2;
  grid-column: 7 / 12;
  width: 100%;
  z-index: 1;
}

.view-only-01 {
  grid-row: -2;
  grid-column: 10 / 13;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.view-only-01 > img,
.view-only-02 > img {
  width: 100%;
  height: 100%;
}

.view-only-01::before,
.view-only-02::before {
  content: "";
  inset: 0;
  position: absolute;
  background-image: linear-gradient(var(--primary--B900), var(--primary--B900));
}

.project-container {
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: 100%;
}

.project-img,
.project-img > img {
  background-size: cover;
  height: 100%;
  width: 100%;
}

.tags {
  display: flex;
  gap: 0.5rem;
}

.tag.html {
  background-color: var(--html-tag);
}

.tag.css {
  background-color: var(--css-tag);
}

.tag.js {
  background-color: var(--js-tag);
}

.tag.xd{
  background-color: var(--adobe-xd-tag);
}

.tag.ae{
  background-color: var(--after-effects-tag);
}

.tag.figma{
  background-color: var(--figma-tag);
}

.project-content {
  align-items: start;
  background-color: var(--secondary--B900);
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  opacity: 0;
  padding: 3.5rem 4.375rem 3.5rem 3.75rem;
  position: absolute;
  transition: all 0.16s ease-in-out;
  height: 100%;
  width: 100%;
}

.project-content:hover {
  opacity: 1;
}

:is(.project-detail-container, .project-detail) {
  display: flex;
  flex-flow: column wrap;
}

.project-detail {
  gap: 1rem;
  grid-column: 1 / 4;
}

.project-container.view-all > .project-content > .project-detail-container {
  margin: auto;
}

.project-detail + a {
  margin-block-start: 3rem;
}

.my-projects-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
}

.my-tools-container {
  align-items: center;
  background-color: var(--secondary--400);
  border-bottom-right-radius: 2.5rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  margin-inline: auto;
}

.my-tools-container-right {
  grid-column: 8 / 12;
  padding-block: 6.25rem;
}

.highlight-02 {
  border-radius: var(--_c-img-b-r);
  grid-row: -2;
  grid-column: 2 / 7;
  width: 100%;
  z-index: 1;
}

.view-only-02 {
  grid-row: 1;
  grid-column: 1 / 4;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.my-tools-list img {
  height: 3rem;
}

.my-tools-list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-width: 24rem;
  grid-column: 8 / 12;
}

/* things i do */

.things-i-do-content {
  margin-inline: auto;
  max-width: 85.25rem;
}

.things-i-do-list-main-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.125rem;
}

.things-i-do-list {
  display: flex;
  flex-flow: column wrap;
}

.things-i-do-list > p {
  border-bottom: 0.063rem solid var(--wz-white);
  padding-bottom: 0.75rem;
  margin-bottom: 2rem;
}

:is(.things-i-do-list-container, .things-i-do-list-content) {
  display: flex;
  flex-flow: column wrap;
}

.things-i-do-list-container {
  gap: 1.5rem;
  padding-right: 1rem;
}

.things-i-do-list-content {
  gap: 0.25rem;
}

/* contact me */

.contact-me {
  background-color: var(--secondary--400);
}

.contact-me-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-inline: auto;
  max-width: 85.25rem;
  padding-block: 8rem;
}

.contact-me-arrow {
  position: relative;
}

.contact-me:hover {
  cursor: pointer;
}

.contact-me-arrow::after {
  content: url(../images/ic-arrow-right-x-large-fill.svg);
  position: absolute;
  inset: 0;
  clip-path: inset(0 100% 0 0);
  transition: clip-path 800ms cubic-bezier(0, 0.8, 0.16, 1);
}

.contact-me:hover .contact-me-arrow::after {
  clip-path: inset(0 0 0 0);
}

/* footer space */

.footer-space {
  --_footer-height: 0;
  display: flex;
  min-height: var(--_footer-height);
  background-color: transparent;
  pointer-events: none;
  width: 100%;
}
