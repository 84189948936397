/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Sora:wght@700;800&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap"); */

:root {
	/* primary colors */
	--wz-white: #ffffff;
	--wz-black: #000000;
	--primary--100: rgba(0, 173, 181, 0.08);
	--primary--200: rgba(0, 173, 181, 0.16);
	--primary--300: rgba(0, 173, 181, 0.24);
	--primary--400: rgb(0, 173, 181);
	--primary--500: rgba(0, 173, 181, 0.32);
	--primary--600: rgba(0, 173, 181, 0.4);
	--primary--700: rgba(0, 173, 181, 0.48);
	--primary--800: rgba(0, 173, 181, 0.56);
	--primary--900: rgba(0, 173, 181, 0.64);
	--primary--A900: rgba(0, 173, 181, 0.72);
	--primary--B900: rgba(0, 173, 181, 0.88);

	/* tags color */
	--html-tag: #e44d26;
	--css-tag: #42a5f5;
	--js-tag: #ffca28;
	--adobe-xd-tag: #FF2BC2;
	--after-effects-tag: #D8A1FF;
	--figma-tag: #0C0E12;

	/* secondary colors */

	--secondary--400: rgba(34, 40, 49);
	--secondary--500: rgba(25, 29, 36);
	--secondary--900-t: rgba(25, 29, 36, 0.48);
	--secondary--B900: rgba(25, 29, 36, 0.96);

	/* background colors */
	--background-color-dark: #191d24;

	/* text colors */
	--text-primary: #ffffff;
	--text-secondary: #bdbdbd;
	--text-tertiary: #eeeeee;
	--text-link-primary: #00adb5;
	--text-link-secondary: #ffffff;
	--text-dark-primary: #191d24;

	/* button text color */

	--button-primary: #ffffff;
	--button-seconday: #00adb5;
	--button-background-primary: #00adb5;
	--button-background-secondary: #222831;

	/* gray colors */
	--gray-050: #fafafa;
	--gray-100: #f5f5f5;
	--gray-200: #eeeeee;
	--gray-300: #e0e0e0;
	--gray-400: #bdbdbd;
	--gray-500: #9e9e9e;
	--gray-600: #757575;
	--gray-700: #616161;
	--gray-800: #424242;
	--gray-900: #212121;

	/* font Sizes */
	--type-link-small: 0.75rem;
	--type-link: 1rem;
	--type-p-small: 0.75rem;
	--type-p: 1rem;
	--type-p-large: 1.25rem;
	--type-caption: 1.25rem;
	--type-heading-h1: clamp(2.5rem, 10vw, 6rem);
	--type-heading-h2: clamp(2.625rem, 10vw, 5rem);
	--type-heading-h3: 4rem;
	--type-heading-h4: 3rem;
	--type-heading-h5: clamp(1.125rem, 5vw, 2rem);
	--type-heading-h6: 1.5rem;

	/* line heights */
	--link-small-line-height: 1rem;
	--link-line-height: 1.25rem;
	--p-small-line-height: 120%;
	--p-line-height: 1.5rem;
	--p-large-line-height: 2rem;
	--caption-line-height: 2rem;
	--heading-h1-line-height: 120%;
	--heading-h2-line-height: 120%;
	--heading-h3-line-height: 5.25rem;
	--heading-h4-line-height: 3.75rem;
	--heading-h5-line-height: 120%;
	--heading-h6-line-height: 1.875rem;

	/* letter spacing */

	--link-small-letter-spacing: 0.125rem;

	/* font family */
	--font-heading: "Sora", var(--font-fall-back);
	--font-body: "Inter", var(--font-fall-back);
	--font-playfair-display: "Playfair Display", var(--font-fall-back);
	--font-fall-back: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
	"Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

	/* font weight variation */
	--font-weight-thin: 100;
	--font-weight-extra-light: 200;
	--font-weight-light: 300;
	--font-weight-regular: 400;
	--font-weight-medium: 500;
	--font-weight-semi-bold: 600;
	--font-weight-bold: 700;
	--font-weight-extra-bold: 800;
	--font-weight-black: 900;

	/* font case */

	--text-capitalize: capitalize;
	--text-uppercase: uppercase;
	--text-lowercase: lowercase;

	/* media query responsive sizes */

	--display-size-small: 575px;
	--display-size-medium: 767px;
	--display-size-large: 991px;
	--display-size-x-large: 1199px;
	--display-size-xx-large: 1399px;
}
