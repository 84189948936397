.header-container {
  position: fixed;
  bottom: 2.5rem;
  width: 100%;
  z-index: 9999;
}

.header-logo {
  max-width: 7.5rem;
  min-width: 7.5rem;
}

.header-container::after {
  content: "";
  background: linear-gradient(
    180deg,
    rgba(25, 29, 36, 0) 0%,
    rgba(25, 29, 36, 0.8) 100%
  );
  bottom: -2.5rem;
  height: calc(100% + 40px);
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 0;
}

.nav-bar-container {
  align-items: center;
  background-color: var(--secondary--400);
  border-radius: 0.375rem;
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;
  margin-inline: auto;
  max-width: 95rem;
  padding: 1rem 2.5rem;
  position: relative;
  width: 100%;
  z-index: 2;
}

.left-navigation {
  align-items: center;
  display: flex;
}

.main-navigation-links {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.main-navigation-links > li > a {
  align-items: center;
  display: flex;
  color: var(--text-link-secondary);
  font-size: var(--type-link-small);
  font-weight: var(--font-weight-semi-bold);
  gap: 0.125rem;
  letter-spacing: var(--link-small-letter-spacing);
  text-transform: uppercase;
  position: relative;
  /* text-decoration: underline;
  text-decoration-color: var(--primary--900);
  text-decoration-thickness: 14px; */
}

.submenu > a::after {
  content: "";
  display: inline-block;
  background-image: url(../images/ic-expand-header.svg);
  height: 0.875rem;
  width: 0.875rem;
}

a.nav-item {
  text-decoration: none;
}

.nav-item::before {
  background-color: var(--primary--900);
  border-radius: 0 0 0.375rem 0.375rem;
  content: "";
  inset: -0.5rem;
  position: absolute;
  top: 1.75rem;
  bottom: -0.25rem;
  transition: 200ms cubic-bezier(0, 0.8, 0.13, 1);
  z-index: -1;
}

.nav-item:hover:before {
  top: 0.75rem;
}

.header-button {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: end;
  margin-inline-start: auto;
}

.logo-container {
  max-width: 7.5rem;
  min-width: 7.5rem;
}

.submenu-wrapper {
  display: none;
}

.submenu-wrapper.open {
  display: block;
  background-color: var(--secondary--400);
  border-bottom: 2px solid var(--primary--400);
  border-radius: 0.375rem;
  box-shadow: 0px 2rem 2.625rem 0px var(--secondary--900-t);
  padding-block: 1.25rem;
  position: absolute;
  bottom: 6.5rem;
}

.submenu-wrapper::before {
  content: "";
  position: absolute;
  height: 4rem;
  width: 100%;
  bottom: -60px;
}

ul.submenu-wrapper.open > li a {
  color: var(--wz-white);
}

ul.submenu-wrapper.open > li {
  font-size: var(--type-link-small);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: var(--link-small-letter-spacing);
  padding-block: 1.25rem;
  padding-inline: 3.75rem;
  text-transform: uppercase;
}

/* .submenu:hover .submenu-wrapper,
.submenu-wrapper:hover .submenu-wrapper {
  display: block;
} */

.mobile-menu-icon,
.lets-chat-btn-mobile {
  display: flex;
}

.d-n {
  display: none;
}

@media screen and (max-width: 112.5em) {
  .main-container.header {
    max-width: 92%;
  }
}

@media screen and (max-width: 87.5em) {
  .main-container.header {
    max-width: 92%;
  }

  .nav-bar-container {
    justify-content: space-between;
  }

  .resume-button,
  .lets-chat-btn {
    display: none;
  }

  .lets-chat-btn-mobile {
    cursor: pointer;
    display: flex;
  }

  .header-button {
    margin-inline-start: 0;
  }
}

@media screen and (max-width: 62.5em) {
  :root {
    --type-link-small: clamp(2rem, 10vw, 3rem);
  }

  .submenu-wrapper.open {
    display: none;
  }

  .mobile-menu-icon {
    display: flex;
    cursor: pointer;
  }

  .nav-bar-container.open {
    background-color: var(--primary--400);
  }

  .main-navigation.open {
    align-items: center;
    background-color: var(--secondary--400);
    border-radius: 0.375rem;
    border-bottom: 2px solid var(--primary--400);
    display: flex;
    justify-content: center;
    position: absolute;
    height: calc(100vh - 9.5rem);
    bottom: calc(4.5rem + 1.25rem);
    left: 0;
    right: 0;
  }

  .main-navigation {
    display: none;
  }

  .main-navigation-links {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .main-navigation-links > li > a {
    --_stroke-width: 1px;
    --_text-shadow-x: var(--_stroke-width);
    --_text-shadow-y: var(--_stroke-width);
    --_text-blur: 0px;
    color: var(--secondary--400);
    font-weight: var(--font-weight-black);
    line-height: 120%;
    text-shadow: var(--_text-shadow-x) var(--_text-shadow-y) var(--_text-blur)
        var(--wz-white),
      calc(-1 * var(--_text-shadow-x)) calc(-1 * var(--_text-shadow-y))
        var(--_text-blur) var(--wz-white),
      var(--_text-shadow-x) calc(-1 * var(--_text-shadow-y)) var(--_text-blur)
        var(--wz-white),
      calc(-1 * var(--_text-shadow-x)) var(--_text-shadow-y) var(--_text-blur)
        var(--wz-white);
  }

  .submenu > a::after {
    content: none;
  }
}

@media screen and (max-width: 47.75em) {
  .header-container {
    bottom: 1.5rem;
  }

  .nav-bar-container {
    justify-content: space-between;
    gap: 1rem;
  }

  .resume-button,
  .lets-chat-btn,
  .web-logo {
    display: none;
  }

  .mobile-menu-icon,
  .lets-chat-btn-mobile,
  .mobile-logo {
    display: flex;
  }
}
