html {
  scroll-behavior: smooth;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

::selection {
  background-color: var(--primary--400);
  color: var(--secondary--400);
}

::-webkit-scrollbar {
  background-color: var(--secondary--500);
  width: 8px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--primary--400);
  border-radius: 6px;
}

/* .bg-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
} */

.main-container {
  width: 100%;
  max-width: 85.41666667%;
  margin: 0 auto;
  /* padding-inline: 24px; */
}

body {
  font-family: var(--font-body);
  font-size: 100%;
  font-weight: var(--font-weight-regular);
  background-color: var(--background-color-dark);
  color: var(--text-primary);
  line-height: var(--p-line-height);
  letter-spacing: 0;
  margin: 0;
  outline: none;
  padding: 0;
}

.button-primary-filled {
  align-items: center;
  color: var(--button-primary);
  background-color: var(--button-background-primary);
  border-radius: 0.25rem;
  display: flex;
  font-size: var(--type-link-small);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: var(--link-small-letter-spacing);
  gap: 0.5rem;
  padding: 0.875rem 1.5rem;
  text-transform: var(--text-uppercase);
}

.button-secondary-filled {
  align-items: center;
  color: var(--button-primary);
  background-color: var(--button-background-secondary);
  border-radius: 0.25rem;
  display: flex;
  font-size: var(--type-link-small);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: var(--link-small-letter-spacing);
  gap: 0.5rem;
  padding: 0.875rem 1.5rem;
  text-transform: var(--text-uppercase);
}

.button-tertiary {
  align-items: center;
  color: var(--text-link-primary);
  display: flex;
  font-size: var(--type-p);
  font-weight: var(--font-weight-medium);
  gap: 1.5rem;
  text-transform: var(--text-capitalize);
}

.tag {
  background-color: var(--primary--400);
  border-radius: 0.375rem;
  color: var(--secondary--500);
  font-weight: var(--font-weight-bold);
  font-size: var(--type-p-small);
  line-height: var(--p-small-line-height);
  margin-block-end: 1rem;
  padding: 0.25rem 0.75rem;
  text-transform: var(--text-uppercase);
}

img {
  box-shadow: none;
  height: auto;
  max-width: 100%;
  outline: none;
}

ul {
  padding: 0px;
  margin: 0px;
}

ul li {
  list-style: none;
}

a {
  box-shadow: none;
  color: var(--text-link-primary);
  display: inline-block;
  text-decoration: none;
  outline: none;
}
a:hover {
  text-decoration: underline;
  cursor: pointer;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}

.p-small-style {
  font-size: var(--type-p-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--p-small-line-height);
}

.p-style {
  font-size: var(--type-p);
  font-weight: var(--font-weight-regular);
  line-height: var(--p-line-height);
}

.p-large-style {
  font-size: var(--type-p-large);
  font-weight: var(--font-weight-regular);
  line-height: var(--p-large-line-height);
}

.caption-style {
  font-family: var(--font-playfair-display);
  font-size: var(--type-caption);
  font-weight: var(--font-weight-bold);
  line-height: var(--caption-line-height);
  padding-block-end: 1em;
}

.heading-h1-style {
  font-family: var(--font-heading);
  font-size: var(--type-heading-h1);
  font-weight: var(--font-weight-extra-bold);
  line-height: var(--heading-h1-line-height);
}

.heading-h2-style {
  font-family: var(--font-heading);
  font-size: var(--type-heading-h2);
  font-weight: var(--font-weight-bold);
  line-height: var(--heading-h2-line-height);
  padding-block-end: 0.5em;
}

.heading-h5-style {
  font-family: var(--font-playfair-display);
  font-size: var(--type-heading-h5);
  font-weight: var(--font-weight-bold);
  line-height: var(--heading-h5-line-height);
}

.heading-h6-style {
  font-size: var(--type-heading-h6);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--heading-h6-line-height);
}

/* h2:has(+ p) {
  margin-bottom: 2.5rem;
}

.content:has(p + h2) h2 {
  margin-block: 0.5em;
} */

.p-project-name-style {
  color: var(--text-tertiary);
  font-size: var(--type-p);
  line-height: var(--p-line-height);
  letter-spacing: var(--link-small-letter-spacing);
}

.p-project-description-style {
  color: var(--text-secondary);
  font-size: var(--type-p);
  line-height: var(--p-line-height);
}

.secondary-font-style {
  font-family: var(--font-playfair-display);
}

.uppercase {
  text-transform: var(--text-uppercase);
}

.emphasize {
  font-style: italic;
}

.strong {
  font-weight: var(--font-weight-bold);
}

.semi-bold {
  font-weight: var(--font-weight-semi-bold);
}

.text-align-center {
  text-align: center;
}

.line-height-none {
  line-height: 0;
}

.primary-color-400 {
  color: var(--primary--400);
}

.text-secondary-color {
  color: var(--text-secondary);
}

.text-tertiary-color {
  color: var(--text-tertiary);
}

.text-dark-primary {
  color: var(--text-dark-primary);
}

.section-margin {
  margin-block: 11.25rem;
}

br.mobile {
  display: none;
}

br.small-mobile {
  display: none;
}
